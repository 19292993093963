import { positionService, departmentService, personnelService } from '@/api';
import { Query, Paging } from '@/api/base';
import { OsTableQuery, OsTable } from '@/components';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { OsTableOption, OsTableColumn } from '@/components/os-table/os-table';
import { DialogMixin } from '@/mixins/dialog';
import { PersonnelResource } from '@/resource/model';
import { messageError } from '@/utils';
import { Table } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class SelectPersonnel extends mixins(DialogMixin) {
  public pageTotal = 0;
  public tableOption: OsTableOption<PersonnelResource> = {
    height: '400px',
    loading: false,
    data: [],
    fit: true
  };

  /**
   * 表格列配置
   */
  public columnOption: Array<OsTableColumn<PersonnelResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'userCode',
      label: 'personnel.code',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    { prop: 'realName', label: 'personnel.realName', minWidth: '100px', showOverflowTooltip: true },
    {
      prop: 'userName',
      label: 'personnel.userName',
      showOverflowTooltip: true,
      minWidth: '100px'
    },
    {
      prop: 'positionNames',
      label: 'personnel.positonName',
      showOverflowTooltip: true,
      minWidth: '140px',
      formatter: (rowData: object): string => {
        return (rowData as PersonnelResource).positionDTOList?.map(x => x.posName).join('，') || '';
      }
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      },
      colSpan: 12
    },
    {
      type: 'Cascader',
      field: 'departmentId',
      label: 'department.department',
      className: 'department',
      option: {
        placeholder: 'department.selectDepartment',
        filterable: true,
        clearable: true,
        showAllLevels: false,
        props: {
          checkStrictly: true,
          emitPath: false
        }
      },
      optionData: [],
      change: (value: number): void => {
        this.getPositions(value);
        this.queryForm.positionId = null;
      },
      colSpan: 12
    },
    {
      type: 'Select',
      field: 'positionId',
      label: 'personnel.positonName',
      option: {
        placeholder: 'position.selectPosition'
      },
      optionData: [],
      colSpan: 12
    }
  ];
  public selectedRows: Array<PersonnelResource> = [];

  public queryForm: Query<{
    keywords: string;
    departmentId: number | null;
    positionId: number | null;
  }> = {};

  private paging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  public dialogOpen(): void {
    this.loadData();
    this.getDepartments();
    this.getPositions();
  }

  public dialogClosed(): void {
    (this.$refs.tableQuery as OsTableQuery).resetQuery();
    this.clearSelection();
  }

  public queryClick(): void {
    this.reloadData();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    (this.$refs.personnelTable as OsTable).clearSelection();
    this.selectedRows = [];
    this.loadData();
  }

  public pagingData(): void {
    this.loadData();
  }

  public handleSelectionChange(selectedData: Array<PersonnelResource>): void {
    this.selectedRows = [...selectedData];
  }

  public onSubmit(): void {
    this.$emit('confirm', this.selectedRows);
    this.syncedVisible = false;
  }

  public get confirmDisabled(): boolean {
    return !(this.selectedRows.length > 0);
  }

  public get selectedNumTemplate(): string {
    return `(${this.selectedRows.length}/${this.pageTotal})`;
  }

  /**
   * 清空选中状态
   */
  public clearSelection(): void {
    ((this.$refs.personnelTable as OsTable).tableRef as Table).clearSelection();
  }

  private loadData(): void {
    this.tableOption.loading = true;
    personnelService
      .getList(this.queryForm as PersonnelResource, this.paging)
      .then(res => {
        this.tableOption.data = res.data || [];
        this.pageTotal = res.total || 0;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private async getDepartments(): Promise<void> {
    try {
      const departments = await departmentService.getAllDepartments();
      const deptQuery = this.queryItemsOption.find(x => x.field === 'departmentId');
      deptQuery!.optionData = departmentService.handleCascaderOption(departments);
    } catch (error) {
      messageError(error);
    }
  }

  private async getPositions(departmentId?: number): Promise<void> {
    try {
      const positionQuery = this.queryItemsOption.find(x => x.field === 'positionId');
      if (!positionQuery) {
        return;
      }
      const positions = departmentId
        ? await positionService.getPositionsByDepartment(departmentId)
        : await positionService.getAllPositions();
      const positionOptions = positions.map(x => {
        return { label: x.posName, value: x.id };
      });
      positionQuery!.optionData = positionOptions;
    } catch (error) {
      messageError(error);
    }
  }
}
