import { personnelService, systemConfigService } from '@/api';
import { MessageConfigList, PersonnelResource, SaveMessageConfig } from '@/resource/model';
import { messageError, translation } from '@/utils';
import { Message } from 'element-ui';
import { Component, Vue } from 'vue-property-decorator';
import SelectPersonnel from '@/views/dialogs/select-personnel/select-personnel.vue';
@Component({
  components: { SelectPersonnel }
})
export default class MessageConfig extends Vue {
  public messageForm: { [p: string]: Array<number> } = {};
  public messageConfigs: Array<MessageConfigList> = [];

  public dialogVisible = false;

  private currentEdit: MessageConfigList | null = null;

  public created(): void {
    this.getMessageConfigs();
  }

  public onSubmit(): void {
    const params: Array<SaveMessageConfig> = this.messageConfigs.map(x => {
      return {
        code: x.code,
        recipient: x.personnels.map(p => p.id).join(','),
        sceneId: x.sceneId
      };
    });
    systemConfigService
      .saveMessageConfig(params)
      .then(() => {
        Message.success(translation('operationRes.saveSuccess'));
      })
      .catch(error => {
        messageError(error);
      });
  }

  public handlePersonnels(personnels: Array<PersonnelResource>): void {
    personnels.forEach(x => {
      if (!this.currentEdit!.personnels.find(p => p.id === x.id))
        this.currentEdit!.personnels.push({ id: x.id, name: x.realName });
    });
  }

  public remove(id: number, item: MessageConfigList): void {
    item.personnels = item.personnels.filter(x => x.id !== id);
  }

  public selectPersonnel(item: MessageConfigList): void {
    this.dialogVisible = true;
    this.currentEdit = item;
  }

  private async getMessageConfigs(): Promise<void> {
    try {
      const [messageConfigs, personnels] = await Promise.all([
        systemConfigService.getMessageConfigs(),
        personnelService.getAllPersonnels()
      ]);
      this.messageConfigs = messageConfigs;
      this.messageConfigs.forEach(x => {
        Vue.set(x, 'personnels', x.recipient ? this.splitPersonnelStr(x, personnels) : []);
      });
    } catch (error) {
      messageError(error);
    }
  }

  private splitPersonnelStr(
    x: MessageConfigList,
    personnels: PersonnelResource[]
  ): { id: number; name: string }[] {
    return x.recipient.split(',').map(x => {
      const personnel = personnels.find(p => p.id === Number(x));
      return {
        id: Number(x),
        name: personnel?.realName ?? '该用户已被禁用或被删除!'
      };
    });
  }
}
